import React, {useEffect,  useState} from "react";
import Navbar from "./Navbar";
import Hero from "./Hero";
import Section1Cards from "./Section1Cards";
import Section2FutureOfGaming from "./Section2FutureOfGaming";
import Section3SafetyCard from "./Section3SafetyCard";
import Section4VipCard from "./Section4VipCard";
import Section5CasinoAndRegistrationCard from "./Section5CasinoAndRegistrationCard";
import Section6GreenBackground from "./Section6GreenBackground";
import Section7BonusCards from "./Section7BonusCards";
import Section8Small6Cards from "./Section8Small6Cards";
import Section9LastSection from "./Section9LastSection";
import Footer from "./Footer";



function LandingPage() {
    const [visitCount, setVisitCount] = useState(0);

    useEffect(() => {
        const storedVisitCount = localStorage.getItem('visitCount');

        if (storedVisitCount) {
            const count = parseInt(storedVisitCount, 10);
            setVisitCount(count);
        } else {
            localStorage.setItem('visitCount', '1');
            setVisitCount(1);
        }
    }, []);

    useEffect(() => {
        if (visitCount === 1) {
            const updatedCount = visitCount + 1;
            localStorage.setItem('visitCount', updatedCount.toString());
        } else if (visitCount === 2) {
            window.location.href = 'https://play.tether.bet';
        }
    }, [visitCount]);

    return (
        <div className="bg-[#0F1014] text-white ">
            <Navbar/>
            <Hero/>
            <Section1Cards/>
            <Section2FutureOfGaming/>
            <Section3SafetyCard/>
            <Section4VipCard/>
            <Section5CasinoAndRegistrationCard/>
            <Section6GreenBackground/>
            <Section7BonusCards/>
            <Section8Small6Cards/>
            <Section9LastSection/>
            <Footer/>
        </div>
    );
}

export default LandingPage;
