import React from "react";

import elipse from "../Assets/Section2Assets/Ellipse57.png";
import Vector from "../Assets/Section3Assets/Vector1.png";

import zadnja from "../Assets/Section3Assets/zadnja.png"

const Section3SafetyCard = () => {
    return (
        <div className="max-w-[1450px] p-5 lg:p-14  lg:h-[485px] flex  justify-center items-center flex-col lg:flex-row mx-auto bg-[rgba(39,39,43,255)] rounded-tl-md rounded-br-md rounded-bl-md relative">

            {/*Image on the right side*/}
            <div className="flex-1 lg:order-1 ">
                <img src={zadnja} alt="" className=" "/>
            </div>
            <img src={Vector} alt="" className="absolute top-0 right-0 w-[63px] h-[59px] "/>
            <div className="  flex-1  ">
                <h4 className=" text-3xl md:text-4xl font-semibold mb-5 ">
                    Secure your peace of mind: Unparalleled safety and security !
                </h4>

                {/*First part */}

                <div className="space-y-3 mb-5 ">
                    <div className="flex flex-row items-center space-x-3  ">
                        <img src={elipse} alt="" className="w-[15px] h-[15px]"/>
                        <h1 className="text-xl leading-[35px]"> Safety first, always!</h1>
                    </div>
                    <p className="text-sm opacity-70 ">
                        Your safety is our utmost priority. <span className="text-[#18E996] lowercase">tether.bet</span> is fully collateralized and maintains sufficient
                        capital reserves at all times, ensuring we cover any betting liability. Play with peace of mind,
                        knowing your funds and winnings are secure.
                    </p>
                </div>

                {/*Second part */}

                <div className="space-y-3 ">
                    <div className="flex flex-row items-center space-x-3">
                        <img src={elipse} alt="" className="w-[15px] h-[15px]"/>
                        <h1 className="text-xl leading-[35px]"> Unmatched security guaranteed!</h1>
                    </div>
                    <p className="text-sm opacity-70  ">
                        Enter a world of unmatched security. Cryptocurrencies are built on blockchain technology,
                        offering multiple layers of protection against possible attacks.
                        Enjoy the confidence that comes with knowing your funds are safeguarded at <span className="text-[#18E996] lowercase">tether.bet. </span>
                    </p>
                </div>
            </div>
        </div>

    )
}

export default Section3SafetyCard
