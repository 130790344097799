import React from "react";

import {Fragment} from 'react'
import {Menu, Transition} from '@headlessui/react'
import britFlag from "../Assets/HeroAssets/BritFLag.png";
import Polygon1 from "../Assets/HeroAssets/Polygon1.png";
import spain from "../Assets/HeroAssets/spain.png";
import france from "../Assets/HeroAssets/france.png";
import portugal from "../Assets/HeroAssets/portugal.png";
import turkey from "../Assets/HeroAssets/turkey.png";
import russia from "../Assets/HeroAssets/russia.png";
import arabia from "../Assets/HeroAssets/saudi-arabia.png";
import iran from "../Assets/HeroAssets/iran.png";
import korea from "../Assets/HeroAssets/south-korea.png";


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Example() {
    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button
                    className=" md:inline-flex h-11 w-20 ml-8 md:ml-0 md:w-20 lg:w-28 md:border-2 md:border-white/50 rounded-md  space-x-2   px-3 py-2 text-sm font-semibold  ">
                    <img src={britFlag} alt="" className="h-6 w-9 inline"/>
                    <span className="ml-1 hidden lg:inline">ENG</span>
                    <img src={Polygon1} alt="" className="absolute right-2 top-4 "/>
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
                >
                <Menu.Items
                    className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                        <Menu.Item className=" ">
                            {({active}) => (
                                <a
                                    href="https://www.google.com"
                                    className={classNames(
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        ' px-4 py-2 text-sm flex flex-row items-center space-x-2'
                                    )}
                                >
                                    <img src={spain} alt="" className=""/>
                                   <p> Español</p>
                                </a>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({active}) => (
                                <a
                                    href="https://www.google.com"
                                    className={classNames(
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        ' px-4 py-2 text-sm flex flex-row items-center space-x-2'
                                    )}
                                >
                                    <img src={france} alt="" className=""/>
                                    <p> Français</p>
                                </a>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({active}) => (
                                <a
                                    href="https://www.google.com"
                                    className={classNames(
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        ' px-4 py-2 text-sm flex flex-row items-center space-x-2'
                                    )}
                                >
                                    <img src={portugal} alt="" className="inline"/>
                                    <p> Português</p>
                                </a>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({active}) => (
                                <a
                                    href="https://www.google.com"
                                    className={classNames(
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        ' px-4 py-2 text-sm flex flex-row items-center space-x-2'
                                    )}
                                >
                                    <img src={turkey} alt="" className="inline"/>
                                    <p> Türkçe</p>
                                </a>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({active}) => (
                                <a
                                    href="https://www.google.com"
                                    className={classNames(
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        ' px-4 py-2 text-sm flex flex-row items-center space-x-2'
                                    )}
                                >
                                    <img src={russia} alt="" className="inline"/>
                                    <p> Русский</p>
                                </a>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({active}) => (
                                <a
                                    href="https://www.google.com"
                                    className={classNames(
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        ' px-4 py-2 text-sm flex flex-row items-center space-x-2'
                                    )}
                                >
                                    <img src={arabia} alt="" className="inline"/>
                                    <p>العربية</p>
                                </a>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({active}) => (
                                <a
                                    href="https://www.google.com"
                                    className={classNames(
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        ' px-4 py-2 text-sm flex flex-row items-center space-x-2'
                                    )}
                                >
                                    <img src={iran} alt="" className="inline"/>
                                    <p>فارسی</p>
                                </a>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({active}) => (
                                <a
                                    href="https://www.google.com"
                                    className={classNames(
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        ' px-4 py-2 text-sm flex flex-row items-center space-x-2'
                                    )}
                                >
                                    <img src={korea} alt="" className="inline"/>
                                    <p> 한국어</p>
                                </a>
                            )}
                        </Menu.Item>
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}
