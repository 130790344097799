import React, {useState} from "react";
import logo from "../Assets/FooterAssets/logo.png"
import Line from "../Assets/Section9Assets/Line.png"
import {FaChevronDown, FaChevronUp, FaDiscord, FaFacebookF, FaInstagram, FaTelegram, FaTwitter} from "react-icons/fa";
import {SiTether} from "react-icons/si";
import Ethereum from "../Assets/FooterAssets/Ethereum.webp"
import Bitcoin from "../Assets/FooterAssets/Bitcoin.webp"
import litecoin from '../Assets/FooterAssets/Litecoin.webp';
import bitcoinCash from '../Assets/FooterAssets/BitcoinCash.webp';
import tron from '../Assets/FooterAssets/Tron.webp';
import dogecoin from '../Assets/FooterAssets/Dogecoin.webp';
import tether from '../Assets/FooterAssets/Tether.webp';
import bnb from '../Assets/FooterAssets/BNB.webp';
import busd from '../Assets/FooterAssets/BUSD.webp';
import solana from '../Assets/FooterAssets/Solana.webp';
import monero from '../Assets/FooterAssets/Monero.webp';
import usdcoin from '../Assets/FooterAssets/USDCoin.webp';
import usdt from '../Assets/FooterAssets/USDT.webp';
import visa from '../Assets/FooterAssets/Arca.webp';
import mastercard from '../Assets/FooterAssets/Mastercard.webp';


const Footer = () => {

    const [isDropdownOpen, setIsDropdownOpen] = useState(true);
    const [isLegalOpen, setIsLegalOpen] = useState(true);
    const [isQuickLinksOpen, setIsQuickLinksOpen] = useState(true);

    const toggleAboutDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const toggleLegalDropdown = () => {
        setIsLegalOpen(!isLegalOpen);
    };

    const toggleQuickLinksDropdown = () => {
        setIsQuickLinksOpen(!isQuickLinksOpen);
    };

    const [hoveredLink, setHoveredLink] = useState('');

    const handleLinkHover = (link) => {
        setHoveredLink(link);
    };


    return (
        <div className="w-full flex flex-col items-center justify-center mx-auto bg-black ">
            <img src={logo} alt="" className="h-11 mt-10"/>
            <img src={Line} alt="" className="w-full h-[1px] lg:max-w-[1450px] px-2.5 lg:px-0 mt-10"/>

            {/*About, legal, social and quick links*/}

            <div className="flex flex-col md:justify-between px-2.5 w-full lg:flex-row max-w-[1450px] mt-10">

                {/*Socials*/}

                <div className="flex flex-col space-y-3 mt-3">
                    <h1 className="text-xl">Follow Us</h1>
                    <div className="flex flex-row space-x-4">
                        <FaFacebookF className="h-5"/>
                        <a href="https://www.facebook.com/officialtetherbet" target="_blank" rel="noreferrer"
                           className={hoveredLink === 'facebook' ? 'text-white' : 'text-gray-400'}
                           onMouseEnter={() => handleLinkHover('facebook')} onMouseLeave={() => handleLinkHover('')}>
                            Facebook
                        </a>
                    </div>
                    <div className="flex flex-row space-x-4">
                        <FaInstagram className="h-5"/>
                        <a href="https://www.instagram.com/tether.bet/" target="_blank" rel="noreferrer"
                           className={hoveredLink === 'instagram' ? 'text-white' : 'text-gray-400'}
                           onMouseEnter={() => handleLinkHover('instagram')} onMouseLeave={() => handleLinkHover('')}>
                            Instagram
                        </a>
                    </div>
                    <div className="flex flex-row space-x-4">
                        <FaTwitter className="h-5"/>
                        <a href="https://twitter.com/tetherbet" target="_blank" rel="noreferrer"
                           className={hoveredLink === 'twitter' ? 'text-white' : 'text-gray-400'}
                           onMouseEnter={() => handleLinkHover('twitter')} onMouseLeave={() => handleLinkHover('')}>
                            Twitter
                        </a>
                    </div>
                    <div className="flex flex-row space-x-4">
                        <FaDiscord className="h-5"/>
                        <a href="https://discord.gg/WAA7dApEQ7" target="_blank" rel="noreferrer"
                           className={hoveredLink === 'discord' ? 'text-white' : 'text-gray-400'}
                           onMouseEnter={() => handleLinkHover('discord')} onMouseLeave={() => handleLinkHover('')}>
                            Discord
                        </a>
                    </div>
                    <div className="flex flex-row space-x-4">
                        <FaTelegram className="h-5"/>
                        <a href="https://t.me/Tetherbet1" target="_blank" rel="noreferrer"
                           className={hoveredLink === 'telegram' ? 'text-white' : 'text-gray-400'}
                           onMouseEnter={() => handleLinkHover('telegram')} onMouseLeave={() => handleLinkHover('')}>
                            Telegram
                        </a>
                    </div>
                </div>

                {/*About*/}

                <div className="flex flex-col space-y-3 text-gray-400 ">

                    <h1 className="text-xl text-white cursor-pointer flex items-center justify-between mt-5 lg:m-0 lg:hidden "
                        onClick={toggleAboutDropdown}>
                        About
                        <span className="lg:hidden">
                             {isDropdownOpen ? <FaChevronUp className="ml-1"/> : <FaChevronDown className="ml-1"/>}
                        </span>
                    </h1>

                    <h1 className="text-xl text-white mt-5  lg:m-0 hidden lg:inline">
                        About
                    </h1>

                    {isDropdownOpen && (
                        <div className="flex flex-col space-y-3">
                            <div>
                                <a href="https://play.tether.bet/terms/about-us"
                                   className={hoveredLink === 'about' ? 'text-white' : 'text-gray-400'}
                                   onMouseEnter={() => handleLinkHover('about')}
                                   onMouseLeave={() => handleLinkHover('')}>
                                    About Us
                                </a>
                            </div>
                            <div>
                                <a href="https://play.tether.bet/terms/contacts"
                                   className={hoveredLink === 'contact' ? 'text-white' : 'text-gray-400'}
                                   onMouseEnter={() => handleLinkHover('contact')}
                                   onMouseLeave={() => handleLinkHover('')}>
                                    Contact Us
                                </a>
                            </div>
                        </div>
                    )}
                </div>

                {/*Legal*/}

                <div className="flex flex-col space-y-3 text-gray-400">
                    <h1 className="text-xl text-white cursor-pointer flex items-center justify-between mt-5 lg:m-0 lg:hidden "
                        onClick={toggleLegalDropdown}>
                        Legal
                        <span className="lg:hidden">
                        {isLegalOpen ? <FaChevronUp className="ml-1"/> : <FaChevronDown className="ml-1"/>}
                        </span>
                    </h1>
                    <h1 className="text-xl text-white mt-5  lg:m-0 hidden lg:inline" >
                       Legal
                    </h1>
                    {isLegalOpen && (
                        <div className="flex flex-col md:flex-row ">
                            <div className="flex flex-col space-y-3 mr-14 mb-3">
                                <div>
                                    <a href="https://play.tether.bet/terms/termsandconditions"
                                       className={hoveredLink === 'terms' ? 'text-white' : ''}
                                       onMouseEnter={() => handleLinkHover('terms')}
                                       onMouseLeave={() => handleLinkHover('')}>
                                        <p>Terms & Conditions</p>
                                    </a>
                                </div>
                                <div>
                                    <a href="https://play.tether.bet/terms/responsiblegaming"
                                       className={hoveredLink === 'gaming' ? 'text-white' : ''}
                                       onMouseEnter={() => handleLinkHover('gaming')}
                                       onMouseLeave={() => handleLinkHover('')}>
                                        Responsible Gaming
                                    </a>
                                </div>
                                <div>
                                    <a href="https://play.tether.bet/terms/rules"
                                       className={hoveredLink === 'rules' ? 'text-white' : ''}
                                       onMouseEnter={() => handleLinkHover('rules')}
                                       onMouseLeave={() => handleLinkHover('')}>
                                        Rules
                                    </a>
                                </div>
                                <div>
                                    <a href="https://play.tether.bet/terms/sports-rules"
                                       className={hoveredLink === 'sports rules' ? 'text-white' : ''}
                                       onMouseEnter={() => handleLinkHover('sports rules')}
                                       onMouseLeave={() => handleLinkHover('')}>
                                        Sports Rules
                                    </a>
                                </div>
                                <div>
                                    <a href="https://play.tether.bet/terms/privacy"
                                       className={hoveredLink === 'privacy' ? 'text-white' : ''}
                                       onMouseEnter={() => handleLinkHover('privacy')}
                                       onMouseLeave={() => handleLinkHover('')}>
                                        Privacy Policy
                                    </a>
                                </div>
                                <div>
                                    <a href="https://play.tether.bet/terms/anti-money-laundering-policy"
                                       className={hoveredLink === 'aml' ? 'text-white' : ''}
                                       onMouseEnter={() => handleLinkHover('aml')}
                                       onMouseLeave={() => handleLinkHover('')}>
                                        Anti-Money Laundering
                                    </a>
                                </div>
                            </div>
                            <div className="flex flex-col space-y-3">
                                <div>
                                    <a href="https://play.tether.bet/terms/dispute-resolution"
                                       className={hoveredLink === 'dispute' ? 'text-white' : ''}
                                       onMouseEnter={() => handleLinkHover('dispute')}
                                       onMouseLeave={() => handleLinkHover('')}>
                                        Dispute Resolution
                                    </a>
                                </div>
                                <div>
                                    <a href="https://play.tether.bet/terms/self-exclusion"
                                       className={hoveredLink === 'exclusion' ? 'text-white' : ''}
                                       onMouseEnter={() => handleLinkHover('exclusion')}
                                       onMouseLeave={() => handleLinkHover('')}>
                                        Self-Exclusion
                                    </a>
                                </div>
                                <div>
                                    <a href="https://play.tether.bet/terms/kyc-policies"
                                       className={hoveredLink === 'kyc' ? 'text-white' : ''}
                                       onMouseEnter={() => handleLinkHover('kyc')}
                                       onMouseLeave={() => handleLinkHover('')}>
                                        KYC Policies
                                    </a>
                                </div>
                                <div>
                                    <a href="https://play.tether.bet/terms/fairness-rng-testing-methods"
                                       className={hoveredLink === 'fairness' ? 'text-white' : ''}
                                       onMouseEnter={() => handleLinkHover('fairness')}
                                       onMouseLeave={() => handleLinkHover('')}>
                                        Fairness & RNG Testing Methods
                                    </a>
                                </div>
                                <div>
                                    <a href="https://play.tether.bet/terms/accounts-payouts-bonuses"
                                       className={hoveredLink === 'accounts' ? 'text-white' : ''}
                                       onMouseEnter={() => handleLinkHover('accounts')}
                                       onMouseLeave={() => handleLinkHover('')}>
                                        Accounts, Payouts & Bonuses
                                    </a>
                                </div>
                            </div>
                        </div>

                    )}
                </div>

                {/*Quick Links*/}

                <div className="flex flex-col space-y-3 text-gray-400">
                    <h1 className="text-xl text-white cursor-pointer flex items-center justify-between mt-5 lg:m-0 lg:hidden" onClick={toggleQuickLinksDropdown}>
                        Quick Links
                        <span className="lg:hidden">
                            {isQuickLinksOpen ? <FaChevronUp className="ml-1"/> : <FaChevronDown className="ml-1"/>}
                        </span>
                    </h1>
                    <h1 className="text-xl text-white mt-5  lg:m-0 hidden lg:inline" >
                       Quick Links
                    </h1>
                    {isQuickLinksOpen && (
                        <div className="flex flex-col space-y-3">
                            <div>
                                <a href="https://play.tether.bet/sports/prematch/soccer#/prematch"
                                   className={hoveredLink === 'sports' ? 'text-white' : ''}
                                   onMouseEnter={() => handleLinkHover('sports')}
                                   onMouseLeave={() => handleLinkHover('')}>
                                    Sports
                                </a>
                            </div>
                            <div>
                                <a href="https://play.tether.bet/casino/games"
                                   className={hoveredLink === 'casino' ? 'text-white' : ''}
                                   onMouseEnter={() => handleLinkHover('casino')}
                                   onMouseLeave={() => handleLinkHover('')}>
                                    Casino
                                </a>
                            </div>
                            <div>
                                <a href="https://play.tether.bet/virtual-sports"
                                   className={hoveredLink === 'virtual' ? 'text-white' : ''}
                                   onMouseEnter={() => handleLinkHover('virtual')}
                                   onMouseLeave={() => handleLinkHover('')}>
                                    Virtual Sports
                                </a>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {/*Tether - USD*/}

            <div className="w-full flex flex-row items-center justify-center mx-auto bg-[#1D2028] space-x-3 p-4 mt-10">
                <SiTether className="w-9 h-9 text-[#18E996]"></SiTether>
                <div className="flex flex-col ">
                    <p className="text-[#18E996] text-xl">1 USD = 1 USDT</p>
                    <p>Parity Guaranteed</p>
                </div>
            </div>

            {/*Payment Methods*/}

            <div className="flex flex-row flex-wrap justify-center lg:space-x-11 mt-20">
                <img src={Bitcoin} alt=""/>
                <img src={Ethereum} alt=""/>
                <img src={litecoin} alt=""/>
                <img src={bitcoinCash} alt=""/>
                <img src={tron} alt=""/>
                <img src={dogecoin} alt=""/>
                <img src={tether} alt=""/>
                <img src={bnb} alt=""/>
                <img src={busd} alt=""/>
                <img src={solana} alt=""/>
                <img src={monero} alt=""/>
                <img src={usdcoin} alt=""/>
                <img src={usdt} alt=""/>
                <img src={visa} alt="" className="h-12"/>
                <img src={mastercard} alt="" className="h-12"/>
            </div>

            {/*Curacao*/}

            <div className="mt-16">
                <a href="https://verification.curacao-egaming.com/validateview.aspx?domain=tether.bet" target="_blank" rel="noreferrer">
                    <img src="https://verification.curacao-egaming.com/validate.ashx?domain=tether.bet" alt="" width="100"/>
                </a>
            </div>
            <div className="text-center px-2.5 max-w-[1450px] mt-11">
                <p className="text-xs">
                    tether.bet is operated by GT Holding B.V. (reg. nr. 156734), Johan Van
                    Walbeeckplein 24, Willemstad,
                    Curaçao, and regulated under gaming license nr. 1668/JAZ issued to Curaçao eGaming, Authorized and
                    Regulated by the Government of Curacao. Payments and financial transactions are processed by GT
                    eServices Ltd (registration number HE 422126) of 30, Peiraios, 1st floor, office 1, 2023, Strovolos,
                    Nicosia, Cyprus.”
                </p>
            </div>
            <div className="my-11">
                <p className="font-extrabold">18+</p>
            </div>
        </div>
    )
}

export default Footer