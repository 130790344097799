import React from "react";



const Section7BonusCards = () => {
    return (
        <div className="max-w-[1450px]  flex justify-center flex-col md:flex-row -translate-y-40  mx-auto">
            <div className="  p-2.5 xl:p-0 w-full flex flex-col md:flex-row justify-between space-y-6 md:space-y-0 md:mt-6 lg:-mt-6 md:space-x-6 z-30 ">

                {/*Bonus left side*/}

                <div className="h-[280px] lg:h-[500px] lg:flex-1  w-full flex flex-col justify-end bg-girl bg-center bg-cover  bg-no-repeat bg-black rounded-lg relative p-7 lg:p-16  ">
                    <h1 className="font-extrabold text-3xl text-white capitalize lg:text-5xl mb-2  "><a href="https://play.tether.bet/promotions/113635" className="hover:text-[#18E996] ">casino bonuses</a></h1>

                    <p className="font-semibold  lg:text-xl">Boost your gaming with enticing casino bonuses. </p>
                    {/*
                    <p className="text-sm opacity-80"> Get up to 2000 USDT bonus on your first deposit!</p>
                       */}
                </div>

                {/*Bonus right side*/}

                <div className="   h-[280px] lg:h-[500px] lg:flex-1  w-full  flex flex-col justify-end bg-ball bg-center bg-cover  bg-no-repeat rounded-lg relative p-7 lg:p-16">
                    <h1 className="font-extrabold text-3xl text-white capitalize lg:text-5xl mb-2 hover:text-[#18E996]"><a href="https://play.tether.bet/promotions/111581" className="hover:text-[#18E996]">sportsbook bonuses</a></h1>
                    <p className="font-semibold  lg:text-xl">Elevate your sports betting with exclusive bonuses. </p>
                    {/*
                    <p className="text-sm opacity-80"> Get up to 200 USDT bonus on your first deposit!!</p>
                          */}
                </div>
            </div>
        </div>
    )
}

export default Section7BonusCards