import React from "react";
import elipse from "../Assets/Section2Assets/Ellipse57.png"
import vip  from "../Assets/Section4Assets/vip4.png"

const Section4VipCard = () => {
    return (
        <div>
            <div className=" max-w-[1450px] lg:h-[485px] flex justify-center items-center  flex-col lg:flex-row mx-auto lg:my-11  p-5 lg:p-14 ">

                {/*Image on the left side*/}
                <div className="flex-1">
                    <img src={vip} alt="" className=" "/>
                </div>
                <div className=" flex-1 ">
                    <h4 className=" text-4xl font-semibold mb-5   ">Ascend to VIP status! </h4>

                    {/*First part */}

                    <div className="space-y-3 mb-5">
                        <div className="flex flex-row items-center space-x-3 ">
                            <img src={elipse} alt="" className="w-[15px] h-[15px]"/>
                            <h1 className="text-xl leading-[35px]"> Experience our VIP program.</h1>
                        </div>
                        <p className="text-sm opacity-70 ">
                             At tether.bet, we believe in delivering an exceptional gaming experience for our VIP players.
                             Join our esteemed VIP Program and unlock a world of exclusive benefits. As you level up through
                             our points program, prepare yourself for unrivaled bonuses and indulge in a personalized gaming
                             journey tailored just for you.
                        </p>
                    </div>

                     {/*Second part */}

                    <div className="space-y-3 ">
                         <div className="flex flex-row items-center space-x-3">
                            <img src={elipse} alt="" className="w-[15px] h-[15px]"/>
                            <h1 className="text-xl leading-[35px]">Oasis of limitless fun.</h1>
                        </div>
                        <p className="text-sm opacity-70  ">
                            Indulge in a gaming paradise like no other at tether.bet. With our unbeatable sportsbook, an
                            extensive selection of thrilling casino games, lightning-fast transactions, unrivaled security
                            measures, and exclusive VIP rewards, your entertainment knows no bounds. Join us today and get a
                            taste of the future of crypto gaming.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Section4VipCard;