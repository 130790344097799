import React from "react";
import logo from "../Assets/HeroAssets/NavbarLogo.png"
import {useEffect, useState} from "react";
import Example from "./languages";
const Navbar = () => {

    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <div className="w-full  sticky top-0 z-50"   >
            <div className={`max-w-[1700px] mx-auto transition duration-100 ${isScrolled ? 'backdrop-blur-md' : ''} `}>
                <div className="navbar flex flex-col    items-center md:flex-row     bg-black text-white bg-transparent ">
                    <div className="logo grid grid-cols-6 md:flex md:ml-14   mx-auto   pt-2 md:pt-0 ">

                            {/*LOGO*/}

                            <a href="https://tether.bet" className="col-start-3 col-span-2 ">
                            <img src={logo} alt="/" className="h-9 md:h-11  "/>
                             </a>

                         {/*Small Language button*/}
                            <div className="hidden">
                             <Example></Example>
                            </div>
                    </div>

                    {/*Buttons*/}
                    {/*Buttons*/}
                    {/*Buttons*/}

                    <div className="menu  p-3  font-semibold flex justify-center space-x-3 md:mr-14 ">
                        <a href="https://play.tether.bet/(modal:auth/login)">
                            <button className=" border-2 border-[#18E996] rounded-md h-11 w-28 md:w-20 lg:w-28">LOGIN</button>
                        </a>
                        <a href="https://play.tether.bet/(modal:auth/register)">
                            <button className='text-black  border-2 border-[#18E996] bg-[#18E996] rounded-md h-11 w-28 md:w-20 lg:w-28'>SIGN UP</button>
                        </a>
                        <div className="hidden md:inline">
                            {/*Language button*/}
                            <div className="hidden">
                            <Example classname="hidden"></Example>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Navbar